import { API } from './api'

export const getStat = async token => {
  return await API.get(`token/stat/${token}`)
}
export const getTransactions = async query => {
  return await API.get(`token/transactions`, query)
}

// 获取k线数据
export const getKline = async query => {
  return await API.get('token/klines', query)
}
