import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TableComponent } from '../../components/Table'
import { debounce } from '../../utils/debounce'
import { getStat, getTransactions } from '../../requests'

const FlexBox = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme.bg1};
  padding: 20px;
  margin-top: 20px;

  .renders{
  color: #21c95e;}
  .render{
  color: #ff5f52;}
  }
`
const ChartTitle = styled.div`
  font-size: 28px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
const ChartBoxCom = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ChartBoxComItem = styled.div`
  flex: 1 1 0%;
  padding-top: 14px;

  .title {
  }
  .value {
    font-size: 28px;
    padding-top: 4px;
  }

  @media (max-width: 768px) {
    min-width: 168px;
    .value {
      font-size: 16px;
    }
  }
`

function formatNumber(value: any) {
  const num = typeof value == 'number' ? value : Number(value)
  if (num >= 1e8) {
    return (num / 1e8).toFixed(1) + '亿'
  } else if (num >= 1e4) {
    return (num / 1e4).toFixed(1) + '万'
  } else {
    return num.toString()
  }
}

function formatString(str: string) {
  if (str.length <= 8) {
    return str // 如果字符串长度小于等于 8，直接返回原字符串
  }
  const start = str.substring(0, 4)
  const end = str.substring(str.length - 4)
  return `${start}...${end}`
}
//获取时间戳到当前时间时间差
function getDateDiff(timestamp: number) {
  const now = new Date().getTime()
  const diff = (now - timestamp) / 1000
  const day = Math.floor(diff / 86400)
  const hour = Math.floor((diff % 86400) / 3600)
  const minute = Math.floor((diff % 3600) / 60)
  const second = Math.floor(diff % 60)
  if(day) return `${day}天前`
  if(hour) return `${hour}小时前`
  if(minute) return `${minute}分钟前`
  return `${second}秒前`
  //return `${day}天 ${hour}小时 ${minute}分 ${second}秒`
}
export default function({ token }: { token: string }) {
  const { t } = useTranslation()
  const data = []

  const render = function({ row }: { row: any }) {
    return <div className={row.type == 1 ? 'renders' : 'render'}>{row.type == 1 ? '购买' : '出售'}</div>
  }
  const columns = [
    {
      lable: t('TableTime'),
      dataIndex: 'createdAt',
      render: ({ row }: { row: any }) => getDateDiff(row.createdAt),
      
      style: {
        minWidth: '120px'
      }
    },
    {
      lable: t('TableType'),
      dataIndex: 'type',
      render: render,
      filters: [
        {
          lable: 'BUY',
          value: 1
        },
        {
          lable: 'Sell',
          value: 0
        }
      ],
      style: {
        minWidth: '120px'
      }
    },
    {
      lable: t('Table$title', { title: token.toUpperCase() }),
      dataIndex: 'amount',
      style: {
        minWidth: '120px'
      }
    },
    {
      lable: "NUSDT",
      dataIndex: 'priceAmount',
      style: {
        minWidth: '120px'
      }
    },
    // {
    //   lable: t('TableUSD'),
    //   dataIndex: 'type',
    //   style: {
    //     minWidth: '120px'
    //   }
    // },
    {
      lable: t('TableWallet'),
      dataIndex: 'address',
      render: ({ row }: { row: any }) => formatString(row.address),
      style: {
        minWidth: '120px'
      }
    }
  ]

  const [stat,setStat] = useState({
    circulatingSupply: 0,
    lockedPosition: 0,
    marketCap: 0,
    trading24H: 0
  })
  const [dataSource, setDataSource] = useState([])
  // [
  //   {
  //     tiem: 'tiem',
  //     type: 0,
  //     ad: 'asdasdasdasdasdassdasdasdasd'
  //   },
  //   {
  //     tiem: 'tiem',
  //     type: 1,
  //     ad: 'asdasdasdasdasdassdasdasdasd'
  //   },
  //   {
  //     tiem: 'tiem',
  //     type: 1,
  //     ad: 'asdasdasdasdasdassdasdasdasd'
  //   },
  //   {
  //     tiem: 'tiem',
  //     type: 0,
  //     ad: 'asdasdasdasdasdassdasdasdasd'
  //   }
  // ]

  //获取统计数据
  const getStasData = async () => {
    
    try {
      const res = await getStat(token.toUpperCase())
       console.log(res,'resresres')
       if(res.code === 0){
        //let {} = res.data
        setStat(res.data)
       }
    } catch (e) {
      console.log(e, 'eeee')
    }

  }
  //获取交易数据
  const getRansactionsData = async () => {
    try {
      const res = await getTransactions({token:token.toUpperCase()})
      console.log(res)
      if(res.code === 0){
        setDataSource(res.data)
        //let {} = res.data
        //setStat(res.data)
       }
    }catch(e) {
      console.log(e)
    }
  }
  useEffect(() => {
    console.log(token, 'tokentoken')
    getStasData()
    getRansactionsData()
    // debounce(()=>{

    // },300)
  }, [token])

  return (
    <>
      <FlexBox>
        <ChartTitle>{t('Stats')}</ChartTitle>
        <ChartBoxCom>
          <ChartBoxComItem>
            <div className="title">{t('TVL')}</div>
            <div className="value">${formatNumber(stat.lockedPosition)}</div>
          </ChartBoxComItem>
          <ChartBoxComItem>
            <div className="title">{t('Market cap')}</div>
            <div className="value">${formatNumber(stat.marketCap)}</div>
          </ChartBoxComItem>
          <ChartBoxComItem>
            <div className="title">{t('FDV')}</div>
            <div className="value">${formatNumber(stat.circulatingSupply)}</div>
          </ChartBoxComItem>
          <ChartBoxComItem>
            <div className="title">{t('1 day volume')}</div>
            <div className="value">${formatNumber(stat.trading24H)}</div>
          </ChartBoxComItem>
        </ChartBoxCom>
      </FlexBox>
      <FlexBox>
        <TableComponent title={t('Transactions')} dataSource={dataSource} columns={columns} />
      </FlexBox>
    </>
  )
}
