import React, { useState, useEffect } from 'react'

import style from './chart.module.css'

import SelectType from '../../components/SelectType'
import ChartComponent from '../../components/ChartComponent'
import Table from './Table'
import { getKline } from '../../requests'

const Chart = props => {
  // 选择币种
  const [currList, setCurrList] = useState([
    {
      name: 'NOVAI',
      value: 'NOVAI',
      lineColor: '#006bff',
      areaColor: '#009bff',
      img: '/images/token/novai.svg'
    },
    {
      name: 'nUSDT',
      value: 'nUSDT',
      lineColor: '#006bff',
      areaColor: '#009bff',
      img: '/images/token/nusd.png'
    },
    {
      name: 'WNOVAI',
      value: 'WNOVAI',
      lineColor: '#006bff',
      areaColor: '#009bff',
      img: '/images/token/novai.svg'
    }
  ])
  // 选中的币种
  const [activeCurr, setActiveCurr] = useState(currList[0])

  const [data, setData] = useState([])

  // 获取图表数据
  const getChartData = async (time = '1') => {
    const res = await getKline({ token: activeCurr.value, type: time })
    if (res.data) {
      // const arr = res.data.map(item => {
      //   return {
      //     ...item,
      //     time: item.time * 1000
      //   }
      // })
      // setData(arr)
      setData(res.data)
    }
  }
  useEffect(() => {
    getChartData()
  }, [activeCurr])

  // 设置时间
  const intervalClick = time => {
    switch (time) {
      case '1H':
        getChartData(1)
        break
      case '1D':
        getChartData(10)
        break
      case '1W':
        getChartData(60)
        break
    }
  }
  return (
    <div className={style.chart}>
      <div className={style.choose}>
        <SelectType list={currList} activeOption={activeCurr} setActiveOption={setActiveCurr} />
      </div>
      <div className={style.chartContent}>
        <ChartComponent {...props} data={data} intervalClick={intervalClick}></ChartComponent>
      </div>
      <Table token={activeCurr.value} />
    </div>
  )
}

export default Chart
